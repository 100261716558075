
import logo from './dark_logo.png';

import './App.css';

function App() {
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        fixes some minor bugs.
        </p>
        <p>coming soon...</p>
        <a href="https://heyzine.com/flip-book/055c7c1fd1.html" className="btn btn__primary" target="_blank" rel="noopener noreferrer">
          <p>Booklet - Türkçe</p>
        </a>
        <a href="https://heyzine.com/flip-book/67fbe20283.html" className="btn btn__secondary" target="_blank" rel="noopener noreferrer">
          <p>Booklet - English</p>
        </a>
      </header>
    </div>
  );
}

export default App;
